import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./reAuth";

export const smApi = createApi({
  reducerPath: "smApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    // account
    updatePassword: builder.mutation({
      query: ({ body }) => ({
        method: "PUT",
        url: "change-password/",
        body
      })
    }),
    updateProfile: builder.mutation({
      query: ({ body }) => ({
        method: "PATCH",
        url: "me/",
        body
      })
    }),
    getUserAddresses: builder.mutation({
      query: () => ({
        method: "GET",
        url: "user-addresses/"
      })
    }),
    createUserAddress: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "user-addresses/create/",
        body
      })
    }),
    updateUserAddress: builder.mutation({
      query: ({ id, body }) => ({
        method: "PATCH",
        url: `user-addresses/${id}/`,
        body
      })
    }),
    deleteUserAddress: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `user-addresses/${id}/delete/`
      })
    }),
    // settings
    fetchMerchant: builder.mutation({
      query: () => "merchant/"
    }),

    // subscriptions
    fetchSubscriptions: builder.mutation({
      query: () => "subscriptions/"
    }),
    fetchSubscriptionPlans: builder.mutation({
      query: (subscriptionId) => `subscriptions/${subscriptionId}/plans/`
    }),
    fetchCheckoutDetails: builder.mutation({
      query: (costId) => `checkout/details/${costId}/`
    }),
    deleteSubscription: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `subscriptions/${id}/delete/`
      })
    }),
    createSubscription: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "subscriptions/create/",
        body
      })
    }),
    updateSubscription: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `subscriptions/${id}/`,
        body
      })
    }),
    // plans
    createPlan: builder.mutation({
      query: ({ id, body }) => ({
        method: "POST",
        url: `subscriptions/${id}/plans/create/`,
        body
      })
    }),
    fetchPlan: builder.mutation({
      query: ({ id }) => ({
        method: "GET",
        url: `subscriptions/plans/${id}/`
      })
    }),
    updatePlan: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `subscriptions/plans/${id}/`,
        body
      })
    }),
    deletePlan: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `subscriptions/plans/${id}/delete/`
      })
    }),
    // user subscriptions
    subscribe: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "subscribe/",
        body
      })
    }),
    cancelUserSubscription: builder.mutation({
      query: ({ id }) => ({
        method: "PATCH",
        url: `subscribe/cancel/${id}/`,
        body: { cancelled: true, active: false }
      })
    }),
    checkCouponData: builder.mutation({
      query: ({ key, plan, subscription, plan_cost }) => ({
        method: "post",
        url: "check-coupon/",
        body: { plan, subscription, key, plan_cost }
      })
    }),

    fetchUserSubscription: builder.mutation({
      query: ({ id }) => ({
        method: "GET",
        url: `user-subscriptions/${id}/`
      })
    }),
    fetchUserSubscriptions: builder.mutation({
      query: (query) => `user-subscriptions/?${query}`
    }),
    //	pms

    // user payment sources
    fetchPaymentSources: builder.mutation({
      query: () => ({
        method: "GET",
        url: "ps/"
      })
    }),
    createPaymentSource: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "ps/",
        body
      })
    }),
    subscribePay: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "subscribe/",
        body
      })
    }),
    verifyPayment: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "verify-payment/",
        body
      })
    }),
    payArb: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "pay/arb/",
        body
      })
    }),
    downloadInvoice: builder.mutation({
      query: ({ id }) => ({
        method: "GET",
        url: `subscriptions/transactions/invoice/${id}/download/`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return blob;
        },
        cache: "no-cache"
      })
    }),
    fetchTapCredentials: builder.mutation({
      query: () => ({
        method: "GET",
        url: "gateways/tap/",
        cache: "no-cache"
      })
    }),
    verifyTapPayment: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "gateways/tap/verify/",
        body
      })
    }),
    verifyPaymentTabby: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "verify-payment/tabby/",
        body
      })
    }),
    calculateCheckoutFee: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "calculate-checkout-fee/",
        body
      })
    }),
    fetchUpgradePromotion: builder.mutation({
      query: ({ id }) => ({
        method: "GET",
        url: `user-subscription-upgrade/${id}/`
      })
    }),
    userSubscriptionUpgradeRequest: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "user-subscription-upgrade/",
        body
      })
    }),
    cancelUserSubscriptionUpgradeRequest: builder.mutation({
      query: ({ id }) => ({
        method: "POST",
        url: `cancel-user-subscription-upgrade/${id}/`
      })
    }),
    calculateUpgradeFee: builder.mutation({
      query: ({ id, body }) => ({
        method: "POST",
        url: `upgrade-promotion-plan-cost/${id}/`,
        body
      })
    }),
    updatePaymentMethod: builder.mutation({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `update-payment-method/${id}/`,
        body
      })
    }),
    tapUpdatePaymentMethod: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "update-tap-payment-method/",
        body
      })
    }),
    checkoutUpdatePaymentMethod: builder.mutation({
      query: ({ body }) => ({
        method: "POST",
        url: "update-checkout-payment-method/",
        body
      })
    }),
    fetchPaymentMethods: builder.mutation({
      query: (query) => ({
        method: "GET",
        url: "payment-methods/?" + query || ""
      })
    }),
    fetchPaymentRequests: builder.mutation({
      query: () => ({
        method: "GET",
        url: "payment_gateways/offline/payment_request/"
      })
    }),
    retrievePaymentRequests: builder.mutation({
      query: (id) => ({
        method: "GET",
        url: `payment_gateways/offline/payment_request/${id}/`
      })
    }),
    getPaymentTriesByRequest: builder.mutation({
      query: (payment_request) => ({
        method: "GET",
        url: `payment_gateways/offline/payment_request/payment_try/list/${payment_request}/`
      })
    }),
    createPaymentRequestTry: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "payment_gateways/offline/payment_request/payment_try/",
        body
      })
    }),
    retrievePaymentRequestTry: builder.mutation({
      query: (id) => ({
        method: "GET",
        url: `payment_gateways/offline/payment_request/payment_try/${id}/`
      })
    }),

    updatePaymentRequestTry: builder.mutation({
      query: ({ id, body }) => ({
        method: "PUT",
        url: `payment_gateways/offline/payment_request/payment_try/${id}/`,
        body

      })
    }),
    getOfflinePaymentOptions: builder.mutation({
      query: () => ({
        method: "GET",
        url: "payment_gateways/offline/payment_options/"
      })
    }),
    retrieveOfflinePaymentOptions: builder.mutation({
      query: (id) => ({
        method: "GET",
        url: "payment_gateways/offline/payment_options/"
      })
    })
  })
});

export const {
  useFetchMerchantMutation,
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
  useFetchSubscriptionsMutation,
  useFetchSubscriptionPlansMutation,
  useFetchCheckoutDetailsMutation,

  useCreateUserAddressMutation,
  useUpdateUserAddressMutation,
  useGetUserAddressesMutation,
  useDeleteUserAddressMutation,
  useCancelUserSubscriptionMutation,
  useCheckCouponDataMutation,
  useSubscribeMutation,
  useFetchUserSubscriptionMutation,
  useFetchUserSubscriptionsMutation,
  useFetchPaymentSourcesMutation,
  useCreatePaymentSourceMutation,
  usePayArbMutation,
  useVerifyPaymentMutation,
  useSubscribePayMutation,
  useDownloadInvoiceMutation,
  useFetchTapCredentialsMutation,
  useVerifyTapPaymentMutation,
  useVerifyPaymentTabbyMutation,
  useCalculateCheckoutFeeMutation,
  useFetchPlanMutation,
  useFetchUpgradePromotionMutation,
  useUserSubscriptionUpgradeRequestMutation,
  useCancelUserSubscriptionUpgradeRequestMutation,
  useCalculateUpgradeFeeMutation,
  useUpdatePaymentMethodMutation,
  useTapUpdatePaymentMethodMutation,
  useCheckoutUpdatePaymentMethodMutation,
  useFetchPaymentMethodsMutation,
  useFetchPaymentRequestsMutation,
  useRetrievePaymentRequestsMutation,
  useGetPaymentTriesByRequestMutation,
  useCreatePaymentRequestTryMutation,
  useRetrievePaymentRequestTryMutation,
  useUpdatePaymentRequestTryMutation,
  useGetOfflinePaymentOptionsMutation,
  useRetrieveOfflinePaymentOptionsMutation

} = smApi;
