import { createApi } from "@reduxjs/toolkit/query/react";
import { AUTH_API } from "../../utils/constants";
import { baseQueryWithReauth } from "./reAuth";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: AUTH_API.TOKEN,
        method: "POST",
        body: credentials
      })
    }),
    requestCode: builder.mutation({
      query: (body) => ({
        url: AUTH_API.REQUEST_CODE,
        method: "POST",
        body
      })
    }),
    verifyCode: builder.mutation({
      query: (body) => ({
        url: body.willShare ? AUTH_API.USER_SHARE_VERIFY_CODE : AUTH_API.VERIFY_CODE,
        method: "POST",
        body
      })
    }),
    signup: builder.mutation({
      query: ({ body }) => ({
        url: AUTH_API.SIGNUP,
        method: "POST",
        body
      })
    }),
    fetchUser: builder.mutation({
      query: () => ({
        url: AUTH_API.ME,
        method: "GET"
      })
    }),
    fieldRequestCode: builder.mutation({
      query: (body) => ({
        url: AUTH_API.FIELD_REQUEST_CODE,
        method: "POST",
        body
      })
    }),
    fieldVerifyCode: builder.mutation({
      query: (body) => ({
        url: AUTH_API.FIELD_VERIFY_CODE,
        method: "POST",
        body
      })
    })
  })
});

export const {
  // useLoginMutation,
  useRequestCodeMutation,
  useVerifyCodeMutation,
  // useSignupMutation,
  useFetchUserMutation,
  useFieldRequestCodeMutation,
  useFieldVerifyCodeMutation

} = authApi;
