import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export const usePaymentMethodsColumns = () => {
  const { t } = useTranslation(["translation"]);

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id"

    },
    {
      title: t("Brand"),
      dataIndex: "brand"
    },
    {
      title: t("Card Number"),
      dataIndex: "last_four",
      render: (value) => <span dir="ltr">**** **** **** {value}</span>
    },
    {
      title: t("Expiry Date"),
      dataIndex: "id",
      render: (value, row) => `${row.expiry_month.toString().padStart(2, "0")}/${row.expiry_year.toString().slice(-2)}`
    },
    {
      title: t("Payment Provider"),
      dataIndex: "type",
      render: (value) => t(value[0].toUpperCase() + value.slice(1).toLowerCase())
    },
    {
      title: t("Subscriptions"),
      dataIndex: "user_subscriptions",
      render: (value) => (
        <div className="d-flex align-items-center justify-content-center flex-column gap-2">
          {value.map((sub, idx) => (
            <NavLink key={value.id} to={`/account/subscriptions/${sub.id}`}>
              {sub.name}
            </NavLink>
          ))}
        </div>
      )
    }
  ];
  return columns;
};

export default usePaymentMethodsColumns;
