import LoadingSpinner from "../../components/LoadingSpinner";
import { MainLayout } from "../../layouts/MainLayout";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useVerifyPaymentMutation, useVerifyTapPaymentMutation, useVerifyPaymentTabbyMutation, useTapUpdatePaymentMethodMutation, useCheckoutUpdatePaymentMethodMutation } from "../../store/services/smApi";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import useQueryParams from "../../utils/useQueryParams";
import { toast } from "react-toastify";

function PaymentProcessing () {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const { "cko-session-id": sessionId, tap_id: tapId, payment_id } = useQueryParams();

  const [verifyTapPayment, { isLoading: isTapLoading }] = useVerifyTapPaymentMutation();
  const [verifyPayment, { isLoading }] = useVerifyPaymentMutation();
  const [verifyPaymentTabby, { isLoading: isTabbyLoading }] = useVerifyPaymentTabbyMutation();
  const [tapUpdatePaymentMethod, { isLoading: isTapUpdatePaymentMethodLoading }] = useTapUpdatePaymentMethodMutation();
  const [checkoutUpdatePaymentMethod, { isLoading: isCheckoutUpdatePaymentMethodLoading }] = useCheckoutUpdatePaymentMethodMutation();
  const [customError, setCustomError] = useState(null);
  const { operation } = useParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verify = async () => {
    let res;
    if (tapId) {
      if (operation === "update-payment-method-process") {
        res = await tapUpdatePaymentMethod({ body: { tap_id: tapId } });
      } else {
        res = await verifyTapPayment({ body: { tap_id: tapId } });
      }
    } else if (payment_id) {
      res = await verifyPaymentTabby({ body: { payment_id } });
    } else {
      if (operation === "update-payment-method-process") {
        res = await checkoutUpdatePaymentMethod({ body: { session_id: sessionId } });
      } else {
        res = await verifyPayment({ body: { session_id: sessionId } });
      }
    }
    console.log("res333", res);
    if (res && res.error) {
      setCustomError(res.error.data.data);
    } else if (res && res.data && res.data.status === "success") {
      if (operation === "update-payment-method-process") {
        toast[res.data.status ?? "success"](res.data.data ?? t("Payment method updated successfully"));
        setTimeout(() => {
          history.push(`/account/subscriptions/${res.data.id}`);
        }, 1000);
      } else {
        history.push("/Checkout?success=true");
      }
    }
  };

  useEffect(() => {
    verify();
  }, []);
  console.log(sessionId);
  return <MainLayout>
    <div>
      <Container className="py-4 py-lg-5">
        <div className="row justify-content-center">
          <div className="col-md-7 col-lg-5">
            <div className="text-center">

              <div className="rounded-3 shadow bg-white p-2 p-sm-5">
                {isTapLoading || isLoading || isTabbyLoading || isTapUpdatePaymentMethodLoading || isCheckoutUpdatePaymentMethodLoading
                  ? <>
                    <h4>{t("Payment is being verified")}...</h4>
                    <LoadingSpinner />

                  </>
                  : null}
                {customError && <span>{customError}</span>}
                {
                                    customError && <>
                                      <p className="mb-0"><NavLink className={"text-primary link"} to={"/"}>Go to home
                                        page</NavLink></p>
                                    </>
                                }
              </div >
            </div >
          </div >
        </div >
      </Container >
    </div >

  </MainLayout >;
}

export default PaymentProcessing;
