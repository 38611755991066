import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import "./styles/globals.css";
import "./styles/checkoutPage.css";
import { Spinner } from "react-bootstrap";
import Routes from "./views/Routes/Routes";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useFetchMerchantMutation, useFetchSubscriptionsMutation } from "./store/services/smApi";
import { useFetchUserMutation } from "./store/services/authApi";
// import { Helmet } from "react-helmet";
import { SiteHelmet } from "./utils/Helmet";

function App () {
  const isLoggedIn = useSelector((state) => state.userStore.isLoggedIn);
  const merchant = useSelector((state) => state.configStore.merchant);
  const [noTenant, setNoTenant] = useState(null);
  const [fetchMerchant, { isLoading: isLoadingMerchant }] =
		useFetchMerchantMutation();
  const [fetchSubscriptions, { isLoading: isLoadingSubscriptions }] =
		useFetchSubscriptionsMutation();
  const [fetchUser, { isLoading: isLoadingUser }] = useFetchUserMutation();

  useEffect(() => {
    const prepare = async () => {
      await fetchSubscriptions();
      const result = await fetchMerchant();
      if (result.error) {
        setNoTenant(true);
      } else {
        setNoTenant(false);
        await fetchUser();
      }
    };
    prepare();
  }, []);
  useEffect(() => {
    if (!merchant) return;
    const root = document.querySelector(":root");
    const RGP = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(merchant.primary_color);
    root.style.setProperty("--bs-shadow", `rgba(${parseInt(RGP[1], 16)}, ${parseInt(RGP[2], 16)}, ${parseInt(RGP[3], 16)}, 0.35`);
    root.style.setProperty("--bs-first", merchant.primary_color);
    root.style.setProperty("--bs-second", merchant.primary_color);
  }, [merchant]);
  if ((!merchant || isLoadingSubscriptions || isLoadingMerchant | isLoadingUser) && noTenant === null) {
    return <div className="text-center"><Spinner animation="border" variant={"success"}/></div>;
  }

  return (
    <>
      {noTenant === false &&
        <BrowserRouter>
          {merchant && <SiteHelmet merchant={merchant}/>}
          <Routes isAuthenticated={isLoggedIn}/>
        </BrowserRouter>
      }
      {noTenant === true && !isLoadingMerchant &&
        <>
          <SiteHelmet merchant={{}} />
          <div className="text-center">No merchant found on given url</div>
        </>}
    </>
  );
}

export default App;
