import {
  CLEAR_AUTH,
  SET_AUTH,
  SET_LAST_CODE_REQUEST
} from "../services/authActions";
import { useSelector } from "react-redux";
import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../services/authApi";

const initialState = {
  token: {
    refresh: null,
    access: null
  },
  isLoggedIn: false,
  last_code_request: null,
  language: "ar"
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = initialState.token;
      state.isLoggedIn = false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setEditableAddress: (state, action) => {
      state.editableAddress = action.payload;
    },
    changeLang (state, action) {
      state.language = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CLEAR_AUTH, (state) => {
        console.log("CLEAR_AUTH");
        // state = initialState
        state.user = null;
        state.token = initialState.token;
        state.isLoggedIn = false;
      })
      .addCase(SET_AUTH, (state, action) => {
        console.log("SET_AUTH");
        state.token = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(SET_LAST_CODE_REQUEST, (state, action) => {
        state.last_code_request = action.payload;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        state.token = action.payload;
        state.isLoggedIn = true;
      })
      .addMatcher(
        authApi.endpoints.fetchUser.matchFulfilled,
        (state, action) => {
          state.user = action.payload;
          state.isLoggedIn = true;
        }
      )
      .addMatcher(
        authApi.endpoints.verifyCode.matchFulfilled,
        (state, action) => {
          state.token = action.payload;
          if (action.payload.access) {
            state.isLoggedIn = true;
          }
        }
      )
      .addMatcher(
        authApi.endpoints.requestCode.matchFulfilled,
        (state, action) => {
          state.last_code_request = Math.floor(Date.now() / 1000);
        }
      )
      .addMatcher(
        authApi.endpoints.fieldRequestCode.matchFulfilled,
        (state, action) => {
          state.last_code_request = Math.floor(Date.now() / 1000);
        }
      );
  }
});

const { actions, reducer } = userSlice;
export const { logout, setToken, setEditableAddress, changeLang } = actions;
export default reducer;
export const useLastCodeRequest = () => useSelector((state) => state.userStore);
